import React from "react"
import '../components/pagination/styles.scss'
import { graphql } from "gatsby"
import ReactPaginate from 'react-paginate'

import Layout from "../components/layout/Layout"
import Seo from "../components/layout/seo"
import {SiteInner, ResultSidebarWrap, ResultsContainer} from "../components/archive/Styles"
import Hotbar from "../components/archive/Hotbar"
import ResultList from '../components/archive/ResultList'
import Sidebar from "../components/sidebar/Sidebar"
import WidgetEmailForm from '../components/sidebar/WidgetEmailForm'
import WidgetDownload from "../components/sidebar/WidgetDownload"
import WidgetFeaturedPostList from "../components/sidebar/WidgetFeaturedPostList"


const CategoryIndex = ({data, pageContext: { pageNumber, totalPages, pageDirectory },}) => {
  const posts = data.allWpPost.nodes

  const handlePageClick = (e) => {
    const selectedPage = e.selected
    const selectedHref = (selectedPage === 0 ? `${pageDirectory}` : `${pageDirectory}${selectedPage+1}`)
    window.open(selectedHref, "_self")
};

  return (
    <Layout>
      <Seo title="Category Page" />

      <Hotbar title={data.wpCategory.name} />
      <SiteInner>
        <ResultSidebarWrap>

          <ResultsContainer>
            <ResultList posts={posts} />

            {/** 
         * Controls pagination links
         * See https://github.com/AdeleD/react-paginate#readme
         */}
        <ReactPaginate
          breakLabel=""
          disableInitialCallback="true"
          forcePage={pageNumber - 1}
          marginPagesDisplayed={0}
          nextLabel=""
          onPageChange={handlePageClick}
          pageRangeDisplayed={9}
          pageCount={totalPages}
          previousLabel=""
          renderOnZeroPageCount={null}
          containerClassName={"container responsive"}
          activeClassName={"active"}
        />
            
          </ResultsContainer>

          <Sidebar>
            <div style={{height: '18px'}}>{/* Empty Div */}</div>
            <WidgetEmailForm />
            <WidgetDownload />
            <WidgetFeaturedPostList />
          </Sidebar>

        </ResultSidebarWrap>

        
      </SiteInner>
    </Layout>
  )
}

export default CategoryIndex

export const pageQuery = graphql`
  query WordPressCategoryPostArchive($id: String!, $offset: Int!, $postsPerPage: Int!) {
    wpCategory(id: {eq: $id}) {
      name
    }
    allWpPost(
      filter: { categories: { nodes: { elemMatch: { id: { eq: $id } } } } }
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        categories {
          category: nodes {
            name
            uri
          }
        }
        author {
          node {
            name
            uri
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 15
                  placeholder: BLURRED
                  layout: CONSTRAINED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`
